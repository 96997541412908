import React from 'react';
import Typography from '../Typography';
import './inputrange.scss';

type Props = {
    max: number;
    min: number;
    name: string;
    value: number;
    onChange: (name: string, value: string) => void;
    leftLabel?: string;
    rightLabel?: string;
    disabled?: boolean;
    required?: boolean;
};

const InputRange: React.FC<Props> = ({
    max,
    min,
    name,
    value,
    onChange,
    leftLabel,
    rightLabel,
    disabled,
    required,
}) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        onChange(name, e.target.value);
    };

    const getBackgroundStyle = () => {
        const width = ((value - min) / (max - min)) * 100;
        return {
            background: `linear-gradient(to right, rgb(45, 175, 227) 0%, rgb(45, 175, 227) ${width}%, #b7b8b9 ${width}%, #b7b8b9 100%)`,
        };
    };

    const getSelectedValue = () => {
        const position = ((value - min) / (max - min)) * 100 + '%';
        return (
            <span className="slider-value" style={{ left: position }}>
                {value}
            </span>
        );
    };

    return (
        <div className="input-range-container">
            {leftLabel && (
                <div className="left-content">
                    <Typography variant="caption">{leftLabel}</Typography>
                </div>
            )}
            <div className="slider-container">
                <div className="marker-container">{getSelectedValue()}</div>
                <input
                    type="range"
                    onChange={handleChange}
                    disabled={disabled}
                    name={name}
                    required={required}
                    value={value}
                    min={min}
                    max={max}
                    step={1}
                    style={getBackgroundStyle()}
                />
                <div className="marker-container">
                    <p className="marker">{min}</p>
                    <p className="marker">{max}</p>
                </div>
            </div>
            {rightLabel && (
                <div className="right-content">
                    <Typography variant="caption">{rightLabel}</Typography>
                </div>
            )}
        </div>
    );
};

export default InputRange;
