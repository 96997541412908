import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AuthActionType, AuthContext } from '../../containers/Authentication/state';
import { UserRole } from '../../models/User';
import Button from '../Core/Button';
import Menu, { MenuItem } from '../Core/Menu';

type Props = {
    activeItem: string;
};

const AccountActions: React.FC<Props> = ({ activeItem }) => {
    const { isLoggedIn, user } = React.useContext(AuthContext).state;
    const dispatch = React.useContext(AuthContext).dispatch;
    const [profileMenuOpen, setProfileMenuOpen] = React.useState(false);
    const AvatarRef = React.useRef<HTMLDivElement>(null);
    const history = useHistory();

    const handleNavigation = (route: string, state?: unknown) => {
        history.push(route, state);
    };

    return (
        <>
            {!isLoggedIn && (
                <div className="account-actions">
                    <Link to="/">
                        <p className={`nav-item ${activeItem === 'Login' ? 'active' : ''}`} role="navigation">
                            <span>Login</span>
                        </p>
                    </Link>
                    <Button color="secondary">CONTACT US</Button>
                </div>
            )}
            {isLoggedIn && (
                <div className="account-actions">
                    {user && (
                        <div className="profile-section">
                            <div
                                onClick={() => setProfileMenuOpen(true)}
                                tabIndex={0}
                                onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) =>
                                    e.key === 'Enter' && handleNavigation('/profile')
                                }
                                ref={AvatarRef}
                            >
                                <p>
                                    {user?.name}
                                    <br />
                                    {user?.role === UserRole.Admin && <span className="user-role">ADMIN</span>}
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            )}
            <Menu open={profileMenuOpen} elementRef={AvatarRef} close={() => setProfileMenuOpen(false)} width={120}>
                <MenuItem
                    label="Logout"
                    onClick={() => {
                        setProfileMenuOpen(false);
                        dispatch({ type: AuthActionType.LOGOUT });
                        handleNavigation('/');
                    }}
                />
            </Menu>
        </>
    );
};

export default AccountActions;
