import { Editor } from '@tinymce/tinymce-react';
import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ContractService, FeedbackService, PerformanceService, ReportService } from '../../api';
import Button from '../../components/Core/Button';
import Typography from '../../components/Core/Typography';
import Contract from '../../models/Contract';
import Report, { ReportType } from '../../models/Report';
import './reportgenerator.scss';
import { NotificationActionType, NotificationContext } from '../../components/Core/Notification/state';
import ContractFeedbacks from './ContractFeedbacks';
import Feedback from '../../models/Feedback';
import Performance, { DataForReport, NACCAdds } from '../../models/ContractPerformance';
import ContractPerformance from '../ContractPerformance';
import getReportTemplate from './ReportTemplate';
import ContractAdds from './ContractAdds/ContractAdd';
import LoadingOverlay from '../../components/LoadingOverlay';

const initReport: Report = {
    content: '',
    reportType: ReportType.Cumulative,
};

const ReportGenerator: React.FC = () => {
    const { contractId, id } = useParams<{ contractId: string; id: string }>();
    const [report, setReport] = React.useState<Report>(initReport);
    const [_contract, setContract] = React.useState<Contract>();
    const [_content, setContent] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const notificationDispatch = React.useContext(NotificationContext).notificationDispatch;
    const [feedbacks, setFeedbacks] = React.useState<Feedback[]>([]);
    const [performanceFilters, setPerformanceFilters] = React.useState();
    const [addsFilters, setAddsFilters] = React.useState();
    const [performanceObject, setPerformanceObject] = React.useState<{ items: Performance[]; total: number }>({
        items: [],
        total: 0,
    });
    const [addsObject, setAddsObject] = React.useState<{ items: NACCAdds[]; total: number }>({ items: [], total: 0 });
    const history = useHistory();
    const _contractId = contractId;
    const location = useLocation();
    const [addsLoaded, setAddsLoaded] = React.useState(false);
    const [spinsLoaded, setSpinsLoaded] = React.useState(false);
    const [feedbackLoaded, setFeedbackLoaded] = React.useState(false);
    const state = location.state as { data: DataForReport; weekData: string };
    const contentData = state?.data as DataForReport;
    const weekData = state?.weekData as string;

    React.useEffect(() => {
        if (id != 'generate-new' && id != 'generate-weekly-new') {
            ReportService.getReportById(id).then((response) => {
                setAddsLoaded(true);
                setFeedbackLoaded(true);
                setSpinsLoaded(true);
                setReport(response);
                setContent(response.content);
            });
        } else {
            setReport({ ...report, contractId: _contractId });
        }
    }, []);

    React.useEffect(() => {
        getFeedback();
        getPerformances();
        getAdds();
    }, [contractId]);

    React.useEffect(() => {
        ContractService.getContract(_contractId).then((response) => {
            if (id == 'generate-weekly-new') {
                setContent(getReportTemplate(response, contentData, weekData));
            } else if (id == 'generate-new') {
                setContent(getReportTemplate(response, contentData, weekData));
            }
            setContract(response);
        });
    }, []);

    const handleEditorChange = (value: string) => {
        setContent(value);
    };

    const getFeedback = () => {
        FeedbackService.getFeedbackForContract(contractId).then((response) => {
            setFeedbackLoaded(true);
            setFeedbacks(response);
        });
    };

    const handleCancel = () => {
        history.goBack();
    };

    const handleSave = () => {
        setLoading(true);
        report.content = _content;
        if (_content.length == 0) {
            notificationDispatch({
                type: NotificationActionType.OPEN,
                payload: {
                    text: `Please enter the report details`,
                    status: 'success',
                    autoClose: true,
                },
            });
        } else {
            {
                !report.id
                    ? ReportService.createReport(
                          id == 'generate-new' ? report : { ...report, reportType: ReportType.Weekly, week: weekData },
                      )
                          .then((_response) => {
                              notificationDispatch({
                                  type: NotificationActionType.OPEN,
                                  payload: {
                                      text: `Report Added Successfully!`,
                                      status: 'success',
                                      autoClose: true,
                                  },
                              });
                              setLoading(false);
                          })
                          .finally(() => {
                              history.push(`/projects/${_contractId}`);
                          })
                          .catch((e) => {
                              notificationDispatch({
                                  type: NotificationActionType.OPEN,
                                  payload: {
                                      text: e.message,
                                      status: 'error',
                                      autoClose: true,
                                  },
                              });
                              setLoading(false);
                          })
                    : ReportService.updateReport(report)
                          .then(() => {
                              notificationDispatch({
                                  type: NotificationActionType.OPEN,
                                  payload: {
                                      text: `Report Updated Successfully!`,
                                      status: 'success',
                                      autoClose: true,
                                  },
                              });
                              setLoading(false);
                          })
                          .finally(() => {
                              history.push(`/projects/${_contractId}`);
                          })
                          .catch((e) => {
                              notificationDispatch({
                                  type: NotificationActionType.OPEN,
                                  payload: {
                                      text: e.message,
                                      status: 'error',
                                      autoClose: true,
                                  },
                              });
                              setLoading(false);
                          });
            }
        }
    };

    const getPerformances = () => {
        PerformanceService.getMatchedPerformancesForGrid(contractId).then((response) => {
            setSpinsLoaded(true);
            setPerformanceObject(response);
        });
    };

    const getAdds = () => {
        PerformanceService.getMatchedAddsForGrid(contractId).then((response) => {
            setAddsLoaded(true);
            setAddsObject(response);
        });
    };

    const handlePerformancePageChange = (page: number) => {
        const request = performanceFilters
            ? PerformanceService.getMatchedPerformancesForGrid(contractId, page, performanceFilters)
            : PerformanceService.getMatchedPerformancesForGrid(contractId, page);
        request.then((response) => {
            setPerformanceObject(response);
        });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handlePerformanceFilterChange = (filters?: any) => {
        if (filters) {
            setPerformanceFilters(filters);
            PerformanceService.getMatchedPerformancesForGrid(contractId, 1, filters).then((response) => {
                setPerformanceObject(response);
            });
        } else {
            setPerformanceFilters(undefined);
            PerformanceService.getMatchedPerformancesForGrid(contractId, 1).then((response) => {
                setPerformanceObject(response);
            });
        }
    };

    const handleAddsPageChange = (page: number) => {
        const request = addsFilters
            ? PerformanceService.getMatchedAddsForGrid(contractId, page, addsFilters)
            : PerformanceService.getMatchedAddsForGrid(contractId, page);
        request.then((response) => {
            setAddsObject(response);
        });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleAddsFilterChange = (filters?: any) => {
        if (filters) {
            setAddsFilters(filters);
            PerformanceService.getMatchedAddsForGrid(contractId, 1, filters).then((response) => {
                setAddsObject(response);
            });
        } else {
            setAddsFilters(undefined);
            PerformanceService.getMatchedAddsForGrid(contractId, 1).then((response) => {
                setAddsObject(response);
            });
        }
    };

    const copyContent = () => {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = _content;
        //tempElement.setAttribute('hidden', 'true');

        document.body.appendChild(tempElement);

        const range = document.createRange();
        range.selectNode(tempElement);
        window.getSelection()?.removeAllRanges();
        window.getSelection()?.addRange(range);

        try {
            const success = document.execCommand('copy');
            notificationDispatch({
                type: NotificationActionType.OPEN,
                payload: {
                    text: success ? `copied to clipboard` : 'error copying',
                    status: success ? 'success' : 'error',
                    autoClose: true,
                },
            });
        } catch (error) {
            console.error('Failed to copy HTML to clipboard: ', error);
        }
        window.getSelection()?.removeAllRanges();

        document.body.removeChild(tempElement);
    };

    return (
        <LoadingOverlay loading={!(feedbackLoaded && addsLoaded && spinsLoaded)}>
            <div className="report-generator">
                <ContractFeedbacks getData={getFeedback} feedbacks={feedbacks} />
                <ContractAdds
                    getData={getAdds}
                    handleFilterChange={handleAddsFilterChange}
                    handlePageChange={handleAddsPageChange}
                    adds={addsObject}
                />
                <ContractPerformance
                    performances={performanceObject}
                    getData={getPerformances}
                    handlePageChange={handlePerformancePageChange}
                    handleFilterChange={handlePerformanceFilterChange}
                />
                <div className="report-content paper">
                    <div className="report-title">
                        <Typography variant="subtitle" weight={600}>
                            REPORT
                        </Typography>
                        <Button onClick={copyContent} color="secondary">
                            copy to clipboard
                        </Button>
                    </div>
                    <br />
                    <Editor
                        apiKey={process.env.REACT_APP_EDITOR_API_KEY}
                        value={_content}
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        onNodeChange={(e: any) => {
                            if (e && e.element.nodeName.toLowerCase() == 'img' && e.element.id !== 'report-logo') {
                                e.element.height = (e.element.height / e.element.width) * 238;
                                e.element.width = 238;
                            }
                        }}
                        init={{
                            menubar: 'file edit view insert format tools table help',
                            plugins:
                                'preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime lists wordcount help quickbars emoticons  noneditable',
                            browser_spellcheck: true,
                            toolbar:
                                'undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | link image| pagebreak | charmap emoticons | fullscreen  preview save print | insertfile link anchor codesample | ltr rtl',
                            quickbars_selection_toolbar:
                                'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                            noneditable_class: 'nonedit',
                            editable_class: 'editcontent',
                            contextmenu: 'image',
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            images_dataimg_filter: function (img: any) {
                                return img.hasAttribute('internal-blob');
                            },
                            content_style: `body {
                                font-family: helvetica, arial, sans-serif; 
                            }`,
                            /* we override default upload handler to simulate successful upload*/
                            images_upload_handler: function (blobInfo) {
                                return ReportService.uploadMedia(
                                    blobInfo.blob(),
                                    `report_file_${new Date().toLocaleDateString()}_${new Date().getMilliseconds()}`,
                                )
                                    .then((response) => {
                                        return response;
                                    })
                                    .catch(() => {
                                        return '';
                                    });
                            },
                            formats: {
                                // Changes the default format for h1 to have a class of heading
                                h1: { block: 'h1' },
                                h2: { block: 'h2' },
                                h3: { block: 'h3' },
                                h4: { block: 'h4' },
                                h5: { block: 'h5' },
                                p: { block: 'p' },
                                caption: { block: 'p' },
                            },
                            style_formats: [
                                // Adds the h1 format defined above to style_formats
                                { title: 'Max Heading', format: 'h1' },
                                { title: 'Heading', format: 'h2' },
                                { title: 'Sub Heading', format: 'h3' },
                                { title: 'Title', format: 'h4' },
                                { title: 'Sub Title', format: 'h5' },
                                { title: 'Body', format: 'p' },
                                { title: 'Caption', format: 'caption' },
                            ],
                        }}
                        onEditorChange={handleEditorChange}
                    />
                    <div className="action-container">
                        <Button onClick={handleCancel} color="error" disabled={loading}>
                            Cancel
                        </Button>
                        <Button onClick={handleSave} color="success" disabled={loading}>
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </LoadingOverlay>
    );
};

export default ReportGenerator;
