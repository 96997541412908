export const setUpBugheard = () => {
    const isStaging = process.env.REACT_APP_ENVIRONMENT === 'staging';
    const bugheardUrl =  process.env.REACT_APP_BUGHEARD_URL;
    //setup bug reporting for dev environments
    if (bugheardUrl && isStaging) {
        const bugheardScript = document.createElement('script');
        bugheardScript.async = true;
        bugheardScript.src = bugheardUrl;
        bugheardScript.type = 'text/javascript'; 
        document.body.appendChild(bugheardScript);
    }
};
